.blurModal :global(.modal-dialog) {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  margin: 0;
}

.blurModal :global(.modal-dialog) :global(.modal-content) {
  height: 100%;
  width: 100%;
  border-radius: 0;
  background: transparent !important;
  border: none;
}

.blurModalBackdrop {
  background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
  opacity: 1 !important;
  transition: all 0.5ms ease;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .blurModalBackdrop {
    background: transparent !important;
    backdrop-filter: blur(60px);
  }
}
