.uploadModal {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3em 0;
}

.uploadModalBackdrop {
  background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .uploadModalBackdrop {
    background: rgba(0, 0, 0, 0.3);
  }
}

.uploadModalContents {
  max-width: 72em;
}

.uploadModalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: solid white;
  border-width: 0 0 1px 0;
}

.uploadModalTitle {
  flex: 1;
}

.crossIcon {
  margin: .3em;
  max-width: 100%;
  max-height: 100%;
}

.closeUploadModal {
  aspect-ratio: 1;
  width: 3em;
  height: 3em;
  padding: 0 !important;
  border-radius: 0 !important;
  margin-bottom: 1em;
}

.uploadModalRow {
  padding: 2em 0;
}

.uploadModalUploadButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadModalUploadButton {
  border-radius: 50%;
  height: 8em;
  aspect-ratio: 1;
  box-shadow: none;
}

.uploadModalUploadCaption {
  margin: 1em 0 0.2em 0;
}

.uploadModalButtonBar {
  display: flex;
  gap: 0.5em;
  flex-direction: row;
}

.uploadModalButtonBarButton {
  min-width: 10em;
  white-space: nowrap;
  padding-left: 3em;
  padding-right: 3em;
}

.tosLabel {
}

.tosCheck {

}

.selectedFileDetails {
  margin: 1em;
  display: flex;
  gap: 5em;
}

.tosBox {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
}

.errorBoxHeader {
  margin-bottom: 1em;
}

.errorBox {
  border: 0.05em solid white;
  padding: 0.7em;
}

.exclamationIcon {
  vertical-align: top;
  margin-right: 0.5em;
}

.error {
  color: red;
}

.warning {
  color: #ffaa00;
}
