/* defining colors used in the app */
:root {
  --economic: #51B195;
  --social: #3C8273;
  --environmental: #30665F;

  --gradient-dark: #1E3D42;
  --gradient-light: #51b195;

  --button-light: #fff;

  --button-light-green: #51b195;
  --button-light-green-rgb: 81, 177, 149;
  --button-light-green-rgba: 81, 177, 149, 50;

  --button-dark: #316660;
  --button-dark-rgb: 49, 102, 96;

  --button-dark-disabled: #4b9f96;
  --button-dark-disabled-rgb: 75, 159, 150;

  --button-dark-hover: #225e58;
  --button-dark-hover-rgb: 34, 94, 88;

  --text-dark: #2F464C;
  --text-dark-secondary: #555958;

  --text-light: #fff;
  --text-light-secondary: #cbcbcb;

  --background-light: #E0EAEC;

}


@font-face {
  font-family: "FrutigerLTComBold";
  src: local("FrutigerLTComBold"),
  url("../fonts/FrutigerLTCom-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "FrutigerLTComRoman";
  src: local("FrutigerLTComRoman"),
  url("../fonts/FrutigerLTCom-Roman.ttf") format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: FrutigerLTComRoman;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
