.introPage {
  padding: 0px !important;
  margin: 0 !important;
  color: white;
}

.introductionRow {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 2em;
}

.sectionHeader {
  border-bottom: 0.1ch solid white;
  margin-bottom: 1em !important;
  margin-top: 2em !important;
  width: 100% !important;

}

.pageTitle {
  margin-bottom: 0.2em !important;
  margin-top: 0 !important;
  width: 100% !important;
}

.noMarginCol {
  padding-left: 0 !important;
}

.introImage {
  //width: 100%;
  object-fit: contain;
  //object-position: top;
  float: right;
  max-width: 50%;
  max-height: 80%;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {
  .introImage {
      float: none;
  }
}

.regulationImageColumn {
}

.howToTextCol {
}

.howToRow {
  align-items: center;
}

.icon {
  margin: 1em;
  fill: white;
  max-width: 5em;
  max-height: 5em;
  object-fit: scale-down;
}

.buttonContinue {
  margin: 1em 0;
  border-radius: 10em !important;
  padding: 0.5em 5em !important;
}

.ytContainer {
  height: 100%;
}

.ytFrame {
  max-height: 100%;
  aspect-ratio: 16/9;
}

.videoDesc {
  //text-align: center;
}

.categoryHeader {
  display: flex;
  align-items: center;
}