.reportCard {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent !important;
  aspect-ratio: 1/1.4142;
  width: 14em;
  max-width: 90%;
  color: whitesmoke;
  overflow: clip;
  transition: all 0.2s ease;
  margin: 0 0 1em 0;
}

.reportCard:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.reportCardContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 0.3em;
  /*noinspection CssInvalidFunction*/
  background: linear-gradient(1turn, var(--gradient-dark), 40%, var(--gradient-light));
  opacity: 0;
  transition: all 0.2s ease;
}

.reportCardContent:hover {
  opacity: 1;
}

.addCard {
  border: 2px dashed white !important;
  border-radius: 0.3em !important;
}

.reportCardBanner {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 30%;
  background-color: var(--economic);
  top: 0;
  padding: 1em 1em;
}

.thumbnail {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.3em;
  background-color: var(--gradient-dark);
  object-fit: cover;
}

.reportSubTitle {
  width: 80%;
  text-align: center;
}

.reportTitle {
  width: 100%;
  text-align: center;
  padding: 0 1em;
}

.buttonBar {
  display: flex;
  gap: 0.4em;
  justify-content: center;
  margin-top: 1em;
}

.addDocText {
  text-align: center;
  padding: 0.5em 1em;
}

.cardProgress {
  padding-left: 0 !important;
  margin-top: 1em !important;
  height: 1em !important;
  background: transparent !important;
  border: 2px solid black; 
}

.cardProgressFill {
  background: white !important; /*linear-gradient(0.25turn, var(--gradient-light), 40%, var(--gradient-dark)) !important;*/
}
