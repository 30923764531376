.uploadFormInputGroup {
  margin: 32px 0;

  .uploadFormLabel {

  }

  .uploadFormInput {
    transition: all 0.2s linear;

    &, &:active, &:focus {
      background: transparent;
      color: var(--button-light);
      outline: none;
      box-shadow: none;
      border: 2px solid white;
    }

    &:hover {
      border-color: hsl(0, 0, 70%);
    }

    &:active, &:focus {
      outline: 0.3em solid rgba(18, 114, 89, 0.50);
    }
  }


  .uploadFormTextInput {

  }

  .uploadFormSelect {
    :global(.form-select__control) {
      @extend .uploadFormInput;

      :global(.form-select__value-container) div {
        color: var(--button-light);
      }

      :global(.form-select__indicators) {
        span {
          background-color: var(--button-light);
        }

        div {
          color: var(--button-light);

          &:hover {
            color: hsl(0, 0, 70%);
          }
        }
      }
    }

    :global(.form-select__menu) {
      //noinspection CssInvalidFunction
      background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
      border: 2px solid white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      :global(.form-select__menu) {
        background: transparent;
        backdrop-filter: blur(20px);
      }
    }

    :global(.form-select__option--is-focused) {
      background: rgba(var(--button-dark-hover-rgb), 0.5);
    }

    :global(.form-select__option):active {
      background: rgba(var(--button-dark-rgb), 0.5);
    }

    :global(.form-select__option--is-selected) {
      background: var(--gradient-dark);
    }

    :global(.form-select__group-heading) {
      color: whitesmoke;
      background: rgba(white, 0.1);
    }
  }

  .uploadFormCheck {
    height: 1.5em;
    width: 1.5em;
    flex-shrink: 0;
    display: grid;
    place-content: center;

    &:checked {
      background: var(--button-light);
      background-image: none !important;
      border-color: var(--button-dark-hover);
    }

    &::before {
      content: "";
      width: 0.75em;
      height: 0.75em;
      transform: scale(0);
      border-radius: 0.15em;
      transition: 0.2s transform ease;
      background: var(--button-dark);
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  .uploadFormCheckLabel {
    flex: 1;
    user-select: none;
  }

  &.uploadFormInputGroupCheck {
    display: flex;
    align-items: baseline;
  }
}


.warning {
  color: red;
}