.body {
  display: flex;
  flex-direction: column;
  padding: 0 10vmin;
  align-items: flex-start;
  overflow: auto;
}

.license {
  padding: 0;
  border: solid 1px;
  margin-bottom: 1%;
  cursor: pointer;
  width: 50%;
 
}
.licenseBody{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3%;
}

.component{
  color: black;
}

.detailsHeader {
  padding: 8px 0 0 0;
  color: white;
}

