.topicButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  background-color: rgb(255, 255, 255, 0);
  color: rgb(90, 90, 90, 1);
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  height: 100%;
}

.topicButton:after {
  content: "";
  float: left;
  background: var(--gradient-dark);
  width: 100%;
  height: 4px;
  border-radius: 3px;
  margin-top: 2px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.topicButtonTitle {
  color: var(--text-dark-secondary);
}

.topicButtonIcon {
  flex: 1;
  object-fit: scale-down;
  height: 5em;
}

.topicSelected {
}

.topicSelected:after {
  opacity: 1;
}

