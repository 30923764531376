.body {
  height: 100vh;
  align-items: flex-start;
  /*noinspection CssInvalidFunction*/
  background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
  color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.bodyTop {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding: 8vmin 16vmin 4vmin 16vmin;
}

.contents {
  flex: 1;
}

.detailsLogo {
  height: 10em;
  padding: 8px 0;
  margin-left: -22px;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.23));
}

.footerLogo {
  padding: 0 8vmin 8vmin 8vmin;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 32px;
}

.separator {
  height: 80%;

  width: 1px;
  min-width: 1px;

  background: white;
}

.poweredByLogo {
  height: 8vh;
  padding: 8px 0;
}

.detailsText {
  padding: 8px 0;
}
.moreInfoButtonText{
  vertical-align: bottom;
  
}
.moreInfo {
  vertical-align: text-bottom;
  border-bottom: 5px solid transparent;
  text-decoration: none;
}

.moreInfo:hover { 
  color: white;
  border-bottom: 0.2em solid white;
}

.detailsHeader {
  margin-top: 2em;
  padding: 8px 0;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.23));
}

.detailsSubHeader {
  padding: 0 0 8px 0;
}

.langToggle {
  padding: 35px 0;
}

.buttonBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.loginButton {
  margin-right: 1em;
  box-shadow: none !important;
}

.registerButton {
  margin: 0 1em;
}

.mainImage {
  width: 40vw;
  align-self: center;
}
