.HeaderPages {
  font-family: "FrutigerLTComBold";
  font-size: 40px;
  color: white;
}

.SubHeaderPages {
  font-family: "FrutigerLTComRoman";
  font-size: 25px;
  color: white;
}

.HeaderPopups {
  font-family: "FrutigerLTComBold";
  font-size: 29px;
  color: white;
}

.SubHeaderPopups {
  font-family: "FrutigerLTComRoman";
  font-size: 23px;
  color: white;
}

.text, .button, .info {
  font-family: "FrutigerLTComRoman";
  font-size: 17px;
  color: white;
}

.smallText {
  font-family: "FrutigerLTComRoman";
  font-size: 13px;
  color: white;
}

.textBold {
  font-family: "FrutigerLTComBold";
  font-size: 17px;
  color: white; 
}

.smallButton {
  font-family: "FrutigerLTComRoman";
  font-size: 10px;
  color: white;
}

.black {
  color: black !important;
}