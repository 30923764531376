.darkgreen {
  background: var(--button-dark) !important;
  border-color: var(--button-dark) !important;
}

.lightgreen {
  background: var(--button-light-green) !important;
  border-color: var(--button-light-green) !important;
}

.primary {
  color: var(--text-light);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10em !important;
  padding: 0.5em 1.5em;
}

.primary:disabled {
  background: darkgray;
  border-color: darkgray;
}

.primary:hover {
  background: var(--button-dark-hover) !important;
  border-color: var(--button-dark-hover) !important;
}

.secondary {
  background: var(--button-light) !important;
  border-width: 0 !important;
  color: var(--text-dark) !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10em !important;
  padding: 0.5em 1.5em;
}

.secondary:hover {
  background: var(--button-dark-hover) !important;
  border-color: var(--button-dark-hover) !important;
  color: var(--text-light) !important;
}

.secondary:focus {
  box-shadow: 0 0 0 0.25rem var(--button-dark-hover)  !important;
}

.secondaryOutline {
  background: transparent !important;
  border: 1px solid var(--button-light) !important;
  color: var(--text-light) !important;
  border-radius: 10em !important;
  padding: 0.5em 1.5em;
}

.secondaryOutline:hover {
  background: var(--button-light) !important;
  color: var(--text-dark) !important;
  border: 1px solid var(--button-light) !important;
}

.smallButton {
  padding: 2px 8px;
  border-radius: 4px;
}

.whiteButton {
  background: white !important;
  color: var(--text-dark) !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid var(--button-dark) !important;
}

.whiteButton:hover {
  background: var(--button-light) !important;
  color: var(--text-dark) !important;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.16);
}
