.navLink {
  padding-left: 24px !important;
  padding-right: 24px !important;
  font-size: 20px;
  font-family: "Helvetica Neue", "Roboto Light", "Segoe UI", sans-serif;
  height: min-content;
}
.appname {
  font-family: "FrutigerLTComBold";
  height: 100%;
  width: 100%;
  font-size: 50px;
  text-align: center;
}

.navbar {
  flex: 1;
  color: whitesmoke;
  height: 100%;
}

.navLinkActive {
  font-weight: bold;
}

.navLinkActive:after {
  content: "";
  float: left;
  background: white;
  width: 100%;
  height: 4px;
  border-radius: 3px;
  margin-top: 2px;
}

.navbarLogo {
  /*padding-right: 80px;*/
  font-size: 24px !important;
  height: 100%;
}

.navbarNav {
  height: 100% !important;
  overflow: clip;
  align-items: center;
}
