.filterRow {
  display: flex;
  padding: 8px 8px 16px 8px;
  background-color: rgb(255, 255, 255, 0.5);
}

.carouselRow {
  max-width: 100%;
  align-items: center;
}

.filterUI {
  width: 100%;
}

.topicCarousel {
  flex: 1;
  overflow: hidden;
}

.topicCarouselNavigationIcon {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  color: black;
}

.topicCarouselNavigationIconDisabled {
  color: gray;
  cursor: unset;
}

.topicCarousel :global(.slick-list) {
  height: 100%;
}

.topicCarousel :global(.slick-list) :global(.slick-track) {
  height: 100%;
}

.topicCarousel :global(.slick-list) :global(.slick-track) :global(.slick-slide) > div {
  height: 100%;
}

.topicCarousel :global(.slick-dots) {
  bottom: 0;
}

.topicCarousel :global(.slick-dots) :global(.slick-active) div {
  background: white;
}
  