.confidenceLegend {
  width: auto;
  margin: 0.8em;
}

.blobConfidence {
  display: flex;
  flex-direction: column;
}

.blobConfidencePercent {
  background: var(--button-dark);
  aspect-ratio: 1;
  height: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: whitesmoke;
  align-self: center;
}

.blobConfidenceContainer {
  display: flex;
  gap: 1em;
  width: auto;
  margin: 1em 0;
}

.legendTitle {
  color: var(--text-dark);
}

.legendText {
  color: var(--text-dark);
  text-align: center;
  width: 100%;
}
