.footerCopyrightNotice {
  padding-left: 64px;
  padding-right: 32px;
}

.footerNav {
  flex: 1;
  color: whitesmoke;
  height: 100%;
}

.footerNavLink {
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-family: "Helvetica Neue", "Roboto Light", "Segoe UI", sans-serif;
  color: rgba(255, 255, 255, .55) !important;
}

.footerNavLink:hover {
  color: #ffffff !important;
}
