.modalBody {
  display: flex;
  flex-direction: column;

  height: 70vh;

  align-items: center;
  justify-content: center;
}

.detailsLogo {
  text-align: center;
  width: 100%;

  cursor: default;
  user-select: none;

  color: #326660;
  filter: drop-shadow(0 3px 6px rgba(50, 102, 96, 0.7));
}

.modal {

}

.mobileText {
  user-select: none;
  text-align: center;

  color: #326660;

  max-width: 13em;
}

.modal > :global(.modal-dialog) {
  min-width: 22rem;
}
