.header {
  display: flex;
  flex-direction: row;
  height: 100px;
  margin-left: 48px;
}

.langToggle {
  width: 256px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.langLabel {
  padding: 4px 0;
  font-size: 13px;
  white-space: nowrap;
}

.langButton {
  margin: 8px 0 !important;
}

.langDropdown {

}

.langDropdown > button:after {
  display: none !important;
}

.profile {
  min-width: 200px;
}

.footer {
  display: flex;
  flex-direction: row;
  height: 64px;
}

.body {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /*noinspection CssInvalidFunction*/
  background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
  overflow-y: hidden;
  overflow-x: hidden;
}

.bodyNoScroll {
  height: 100vh;
  max-height: 100vh;
  /*noinspection CssInvalidFunction*/
  background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
  overflow: clip;
}

.contentMargin {
  margin-left: 48px;
  margin-right: 48px;
}

.contentContainer {
  min-height: 0;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.contentContainerNoScroll {
  height: calc(100vh - 164px);
}
