.reportLoader {
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3em 0;
}

.placeholder {
  color: black;
  background-color: whitesmoke;
  border: black solid 0.2em;
  max-height: 400px;
  height: 100%;
}

.reportProgress {
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 1em !important;
  background: transparent !important;
  border: 2px solid black; 
}

.reportProgressFill {
  background: white !important; /*linear-gradient(0.25turn, var(--gradient-light), 40%, var(--gradient-dark)) !important;*/
}

.mainText {
  word-break: break-all;
}

.noLeftPad {
  padding-left: 0 !important;
}

.customRow {
  padding-left:0 !important;
  padding-right:0 !important;
  margin-left:0 !important;
  margin-bottom: 1em;
  margin-right:0 !important;
}

.mainContentRow {
  flex-grow: 2;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em !important;
}

.button {
  width: fit-content !important;
}

.mainContentCol {
  display: flex;
  align-items: center;
}

.ytFrame {
  max-height: 100%;
  aspect-ratio: 16/9;
}
