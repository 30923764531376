.predictionBoxContainer {
  padding: .5em 0.8em;
  margin: unset;
}

.expandArrow {
  background: var(--button-dark);
  aspect-ratio: 1;
  height: 2.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  color: whitesmoke;
}

.predictionBlobCount {
  background: var(--button-dark);
  aspect-ratio: 1;
  height: 2.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: whitesmoke;
}

.predictionHeaderTitle {
  background: var(--background-light);
  color: var(--text-dark-secondary);
  padding: 8px;
  flex: 1;
  margin: 0 8px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.expandArrowIcon {
  transition: all .3s ease;
  scale: 150%;
}

.expandArrowIcon.flipped {
  transform: rotate(-180deg);
}

.predictionHeader {
  display: flex;
  justify-content: space-between;
  background: white;
}

.predictionBoxBody {
  overflow-y: clip;
}

.predictionBox {
  background-color: unset !important;
  overflow: clip;
}

.containerDescription {
  width: 100%;
}

.headerDescription {
  border: solid rgba(0, 0, 0, .03);
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  padding: 0 8px;
  color: whitesmoke;
  background-color: var(--button-dark);
}

.bodyDescription {
  color: var(--text-dark-secondary);
  border: 2px solid rgba(0, 0, 0, .03);
  padding: 0 8px;

  white-space: pre-wrap;
}

.blobPagination {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.blobPagination :global(.page-item) :global(.page-link) {
  color: var(--text-dark);
}

.blobPagination :global(.page-item.active) :global(.page-link) {
  background: var(--button-dark);
  border-color: var(--button-dark);
  color: whitesmoke;
}
