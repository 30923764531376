.Row {
  height: 100%;
  max-height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  margin: 0px !important;
}

.mainContainer {
  width: 100% !important;
  height: 100% !important;
  background: white;
}

.requirementCol,
.pdfViewerCol {
  height: 100%;
  display: block;
}

.titleRow {
  padding: 1em;
}

.ReportTitle {
  padding: 1em;
  width: auto;
}

.underline {
  min-height: 0.4em;
  border-radius: 1em 1em 1em 1em;
  background: linear-gradient(0.35turn, rgba(28, 62, 64), rgba(82, 177, 150), rgba(56, 2, 155, 0));
}

.requirementCol {
  background-color: unset !important;
  border: unset;
  overflow: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.requirementList {
  padding-bottom: 1em;
}

.pdfViewerCol {
  margin-left: auto;
  margin-right: auto;
  padding: 0 !important;
}

.pdfPage {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pdfViewer {
  height: 100%;
}
