.categoryButtonContainer {
  flex-grow: 1;
}

.soziales {
  text-align: center;
  padding: 10px;
  margin: 5px;
  background-color: rgb(60, 130, 115, 0.5);
  color: rgb(255, 255, 255, 0.5);
  border-radius: 5px;
  -moz-box-shadow: 0 0 1em rgba(0, 0, 0, 0.282);
  -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.282);
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.282);
}

.activeSoziales {
  background-color: rgb(60, 130, 115);
  color: rgb(255, 255, 255, 1);
  -moz-box-shadow: 0 0 1em #00000048;
  -webkit-box-shadow: 0 0 1em #00000048;
  box-shadow: 0 0 1em #00000048;
}

.pointerSoziales  {
  height: 20px;
  width: 20px;
  background: rgb(60, 130, 115);
  margin: -15px auto;
  transform: rotate(225deg);
  border-radius: 0 0 500px 0;
  left: calc(0.5vw - 50px);
}

.enviro {
  text-align: center;
  padding: 10px;
  margin: 5px;
  background-color: rgb(48, 102, 95, 0.5);
  color: rgb(255, 255, 255, 0.5);
  border-radius: 5px;
  -moz-box-shadow: 0 0 1em rgba(0, 0, 0, 0.282);
  -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.282);
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.282);
}

.activeEnviro {
  background-color: rgb(48, 102, 95);
  color: rgb(255, 255, 255, 1);
  -moz-box-shadow: 0 0 1em #00000048;
  -webkit-box-shadow: 0 0 1em #00000048;
  box-shadow: 0 0 1em #00000048;
}

.pointerEnviro  {
  height: 20px;
  width: 20px;
  background: rgb(48, 102, 95);
  margin: -15px auto;
  transform: rotate(225deg);
  border-radius: 0 0 500px 0;
  left: calc(0.5vw - 50px);
}

.eco {
  text-align: center;
  padding: 10px;
  margin: 5px;
  background-color: rgb(81, 177, 149, 0.5);
  color: rgb(255, 255, 255, 0.5);
  border-radius: 5px;
  -moz-box-shadow: 0 0 1em rgba(0, 0, 0, 0.282);
  -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.282);
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.282);
}

.activeEco {
  background-color: rgb(81, 177, 149);
  color: rgb(255, 255, 255, 1);
  -moz-box-shadow: 0 0 1em #00000048;
  -webkit-box-shadow: 0 0 1em #00000048;
  box-shadow: 0 0 1em #00000048;
}

.pointerEco  {
  height: 20px;
  width: 20px;
  background: rgb(81, 177, 149);
  margin: -15px auto;
  transform: rotate(225deg);
  border-radius: 0 0 500px 0;
  left: calc(0.5vw - 50px);
}