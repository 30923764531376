.containerBlob {
  width: 100%;
  display: flex;
  margin: 1em 0;
}

.blobContent {
  background: var(--background-light);
  color: var(--button-dark);
  padding: 8px;
  flex: 1;
  border-radius: 4px;
  overflow-y: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 0 1em;
  cursor: pointer;
  min-height: 100%;
}

.blobDisplayContent {
  color: var(--button-dark);
  white-space: pre-wrap;
}

.blobConfidence {
  background: var(--button-dark);
  aspect-ratio: 1;
  height: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: whitesmoke;
  align-self: center;
}

.blobButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.blobButton {
  background-color: var(--button-dark) !important;
  aspect-ratio: 1;
  display: flex;
  height: 2em;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border-width: 0;
  border-color: transparent !important;
  padding: 0;
  color: whitesmoke;
}

.blobButton:disabled {
  background-color: var(--button-dark-disabled) !important;
}

.blobButton:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--button-light-green-rgba)) !important;
}

.blobButton:hover > .iconHoverInvisible {
  display: none;
}

.blobButton:hover > .iconHoverVisible {
  display: unset;
}

.resetIcon {
  display: none;
}

.feedbackIcon {

}

.headerBlob {
  width: 100%;
  border: solid rgba(0, 0, 0, .03);
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  padding: 0 8px;
  background-color: #468fea;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.bodyBlob {
  width: 100%;
  border: solid rgba(0, 0, 0, .03);
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  padding: 0 8px;
}



