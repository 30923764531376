.loadingModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: transparent;
  color: white;
  height: 100%;
  width:100%;
}

.loadingLabel {
  margin: 1em 0;
  user-select: none;
}

.loadingIcon {

}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
