.reportSubheader {
  margin: 0.5em 0 0.2em 0;
  width: 100%;
}

.filesPage {
  color: #FFF;
  padding: 0 3em;
  width: 100%;
  padding-left: 0 !important;
}

.reportHeader {
  margin: 0 0;
  width: 100%;
}

.reportsRow {
  margin-top: 1em;
}

.reportDescription {
  margin: 0.4em 0 2em 0;
  width: 100%;
}
