.reportPreviewBackdrop {
  //noinspection CssInvalidFunction
  background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .reportPreviewBackdrop {
    background: rgba(0, 0, 0, 0.3);
  }
}

.reportPreviewContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 1000px;
  padding: 3em 0;

  .reportPreviewTitle {
    margin: 0;
  }

  .reportPreviewSubTitle {
    margin: 0;
    position: relative;

    .closePreviewModal {
      width: 3em;
      height: 3em;
      aspect-ratio: 1;

      padding: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 0.5em !important;

      position: absolute;

      right: -5em;
      bottom: -3.8em;
    }
  }

  .reportPreviewPdf {
    flex: 1;
    width: 100%;
    margin: 1em 0;
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pdfPreviewer {
      padding: 0;
      height: 100%;

      .pdfPageClassName {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }

    .reportPreviewError {
      width: auto;
      margin: 0.5em 0;
    }
  }

  .reportPreviewButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    .reportPreviewButton {
      width: auto;
      padding-left: 2em;
      padding-right: 2em;
      margin: 1em 0;
    }
  }
}