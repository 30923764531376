.markdownPage {
  color: white;
  white-space: pre-line;
}

:root {
  --table-border: 1px solid white;
}

:global(blockquote) {
  padding-left: 4em;
}

:global(p), :global(ul), :global(li) {
  margin: 0;
  white-space: normal;
}

:global(table) {
  border: var(--table-border);
}

:global(td), :global(th) {
  padding: .1em 2em .5em .5em;
}

:global(th) {
  text-align: center;
  border-bottom: var(--table-border);
}

:global(blockquote) > :global(table) {
  border: none;
}

:global(blockquote) :global(th) {
  padding: 0;
  border: none;
}

:global(blockquote) :global(td) {
  padding: .1em 2em .1em 0;
}

